import clsx from "clsx";
import React from "react";
import { get } from "lodash";
import Link from "next/link";
import IconBox from "../IconBox";
import { useRouter } from 'next/router';
import CartService from "@root/services/cart";
import LocaleService from "@root/services/locale";

import styles from "./styles.module.scss";

function BottomTabs() {
  const router = useRouter();
  const { t } = LocaleService.useLocale();
  const { cartData } = CartService.useCartData();

  const itemCount = () => {
    return get(cartData, ['items_count'], 0);
  }

  const path = (value) => {
    return router.asPath == value;
  }

  return (
    <ul className={styles.tabs}>
      <li className={styles.tabs_item}>
        <Link href="/">
          <span className={clsx(styles.tabs_link, path('/') && styles.tabs_active)}>
            <IconBox name="home-outline" className={styles.tabs_icon} />
            <span className={styles.tabs_title}>{t('home_title')}</span>
          </span>
        </Link>
      </li>
      <li className={styles.tabs_item}>
        <Link href="/categories">
          <span className={clsx(styles.tabs_link, path('/categories') && styles.tabs_active)}>
            <IconBox name="format-list-bulleted" className={styles.tabs_icon} />
            <span className={styles.tabs_title}>{t('categories_title')}</span>
          </span>
        </Link>
      </li>
      <li className={styles.tabs_item}>
        <Link href="/rewards-landing">
          <span className={clsx(styles.tabs_link, path('/rewards-landing') && styles.tabs_active)}>
            <IconBox name="crown" className={styles.tabs_icon} />
            <span className={styles.tabs_title}>{t('rewards_title')}</span>
          </span>
        </Link>
      </li>
      <li className={styles.tabs_item}>
        <Link href="/account">
          <span className={clsx(styles.tabs_link, path('/account') && styles.tabs_active)}>
            <IconBox name="account-outline" className={styles.tabs_icon} />
            <span className={styles.tabs_title}>{t('account_title')}</span>
          </span>
        </Link>
      </li>
      <li className={styles.tabs_item}>
        <Link href="/cart">
          <span className={clsx(styles.tabs_link, path('/cart') && styles.tabs_active)}>
            <IconBox name="cart-outline" className={styles.tabs_icon} />
            <span className={styles.tabs_title}>{t('cart_title')}</span>
            <span className={styles.tabs_bubble}>{itemCount()}</span>
          </span>
        </Link>
      </li>
    </ul>
  );
}

export default BottomTabs;
