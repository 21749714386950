import {get, isNull} from 'lodash';
import AuthService from './auth';
import config from '@root/config';
import LoggerService from './logger';
import StorageService from './storage';
import RequestService from './request';
import {of, merge, catchError} from 'rxjs';
import {isValidString} from '@root/libs/utils';
import {map as rxmap, tap} from 'rxjs/operators';
import EventsService, {
  LOGOUT,
  RESET_CART,
  ORDER_SUCCESS,
  ORDER_FAILURE,
} from './events';

class Quote {
  constructor() {
    this._quoteRequest = null;
    merge(
      EventsService.$event(LOGOUT),
      EventsService.$event(RESET_CART),
      EventsService.$event(ORDER_SUCCESS),
      EventsService.$event(ORDER_FAILURE),
    ).subscribe(() => {
      this.clearQuote();
    });

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  get $quote() {
    if (isValidString(this.quote)) return of(this.quote);
    else if(!isNull(this._quoteRequest)) return this._quoteRequest;
    else return this.fetchQuote().pipe(tap(quote => this.setQuote(quote)));
  }

  get isGuestQuote() {
    return (
      isValidString(this.quote) &&
      this.quote.length > config.resider_qoute_max_length
    );
  }

  get isResiderQuote() {
    return (
      isValidString(this.quote) &&
      this.quote.length < config.resider_qoute_max_length
    );
  }

  get quote() {
    return StorageService.read(config.quoteKey);
  }

  setQuote(value) {
    StorageService.write(config.quoteKey, value);
  }

  clearQuote() {
    StorageService.remove(config.quoteKey);
  }

  fetchQuote() {
    this._quoteRequest = this._request().pipe(
      rxmap(result => get(result, ['data'], null)),
      tap(() => this._quoteRequest = null),
      catchError(error => {
        // TODO: add session timeout logic
        LoggerService.logError('Fetch qoute error!', error);
        return of(null);
      }),
    );
    return this._quoteRequest;
  }

  _request() {
    if (AuthService.isAuth)
      return RequestService.$authPost(`${config.base_path}rest/V2/carts/mine`);
    else return RequestService.$post(`${config.base_path}rest/V1/guest-carts`);
  }
}
const QuoteService = new Quote();
export default QuoteService;
