import AuthService from './auth';
import config from '@root/config';
import QuoteService from './quote';
import LoggerService from './logger';
import {toast} from 'react-toastify';
import RequestService from './request';
import {useEffect, useState} from 'react';
import {isValidString} from '@root/libs/utils';
import LocaleService from '@root/services/locale';
import TrackingService from '@root/services/tracking';
import {forEach, isNull, get, extend, toString} from 'lodash';
import {map as rxmap, switchMap, tap, filter as rxfilter} from 'rxjs/operators';
import {
  BehaviorSubject,
  firstValueFrom,
  merge,
  of,
  catchError,
  throwError,
} from 'rxjs';
import EventsService, {
  LOGOUT,
  RESET_CART,
  ORDER_FAILURE,
  ORDER_SUCCESS,
  PICK_LOCATION,
  FALSH_SALES_ADD_TO_CART,
} from '@root/services/events';

export const EXPRESS = 1;
export const STANDARD = 0;

class Cart {
  constructor() {
    this.request = null;
    this.$behaviorCart = new BehaviorSubject(null);

    this._clearCartRoutine();
    this._initCartDataRoutine();

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  useCartData() {
    const [cartData, setCartData] = useState({});

    useEffect(() => {
      let cart = this.$behaviorCart.subscribe(data => {
        if (!data) return;

        let items = get(data, ['items'], []);
        let express = get(items, [0, 'items'], []);
        let standard = get(items, [1, 'items'], []);
        data['flat'] = [...express, ...standard];
        setCartData(data);
      });
      return () => cart.unsubscribe();
    }, []);

    return {
      cartData,
    };
  }

  // TODO: Handle guest to resider session timeout case
  guestToResider(userId) {
    return firstValueFrom(
      QuoteService.$quote.pipe(
        switchMap(quote => {
          let storeid = LocaleService.isArabic ? '4' : '3';
          return RequestService.$authPut(
            `${config.base_path}rest/V2/guest-carts/${quote}`,
            {body: {storeId: storeid, customerId: userId}},
          );
        }),
        tap(result => {
          let message = get(result, ['message'], null);
          if (isValidString(message)) toast.info(message);

          this.$behaviorCart.next(get(result, ['data'], null));
          QuoteService.setQuote(get(result, ['data', 'quote_id'], null));
        }),
        catchError(error => {
          QuoteService.clearQuote();
          this.$behaviorCart.next(null);
          LoggerService.logError('Guest to resider failed!', error);
          return of({});
        }),
      ),
    );
  }

  setCartData(cart) {
    let data = get(cart, ['data'], {});
    let cartData = this.$behaviorCart.getValue();
    let extended = extend({}, cartData, data);
    this.$behaviorCart.next(extended);
  }

  itemsCount(items) {
    let count = 0;
    forEach(items, item => {
      count = count + parseInt(item.qty);
    });
    return count;
  }

  addToCart(sku, option, recommendations, from) {
    return QuoteService.$quote.pipe(
      switchMap(quote =>
        this._requestAdd({sku, quote, option, recommendations}),
      ),
      tap(result => this.setCartData(result)),
      catchError(error => {
        if (error.code === 406) {
          EventsService.trigger({type: PICK_LOCATION});
        }
        if (error.code === 404 || error.code === 400) {
          // clearing cart, shopping already done
          QuoteService.clearQuote();
          this.$behaviorCart.next({});
        }
        if (from === 'flash_sale_item') {
          EventsService.trigger({type: FALSH_SALES_ADD_TO_CART});
        }
        return throwError(() => error);
      }),
    );
  }

  updateCart(id, sku, qty, from) {
    return QuoteService.$quote.pipe(
      switchMap(quote => this._requestUpdate({id, sku, qty, quote})),
      tap(result => this.setCartData(result)),
      catchError(error => {
        if (error.code === 406) {
          EventsService.trigger({type: PICK_LOCATION});
        }
        if (error.code === 404 || error.code === 400) {
          // clearing cart, shopping already done
          QuoteService.clearQuote();
          this.$behaviorCart.next({});
        }
        if (from === 'flash_sale_item') {
          EventsService.trigger({type: FALSH_SALES_ADD_TO_CART});
        }
        return throwError(() => error);
      }),
    );
  }

  removeFromCart(id, type) {
    return QuoteService.$quote.pipe(
      switchMap(quote => this._requestRemove({id, quote, type})),
      tap(result => this.setCartData(result)),
    );
  }

  fetchData() {
    return QuoteService.$quote.pipe(
      switchMap(quote => this._requestDetail({quote})),
      rxmap(result => get(result, ['data'], {})),
      tap(result => {
        let quote = get(result, ['quote_id'], null);
        if (isValidString(quote)) QuoteService.setQuote(quote);
      }),
    );
  }

  switchDeliveryType(id, sku, qty, type) {
    return QuoteService.$quote.pipe(
      switchMap(quote => this._requestSwitch({id, sku, qty, quote, type})),
      tap(result => this.setCartData(result)),
      catchError(error => {
        if (error.code === 404) {
          // clearing cart, shopping already done
          QuoteService.clearQuote();
          this.$behaviorCart.next({});
        }
        return throwError(() => error);
      }),
    );
  }

  switchAllDeliveryType(type) {
    return QuoteService.$quote.pipe(
      switchMap(quote => this._requestSwitchAll({quote, type})),
      tap(result => this.setCartData(result)),
      catchError(error => {
        if (error.code === 404) {
          // clearing cart, shopping already done
          QuoteService.clearQuote();
          this.$behaviorCart.next({});
        }
        return throwError(() => error);
      }),
    );
  }

  fetchCartRules() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V2/carts/rules`,
    );
  }

  _initCartDataRoutine() {
    /**
     * Fetch cart data if
     * resider with resider quote or
     * guest with guest quote
     */
    AuthService.$isAuth
      .pipe(
        rxfilter(auth => {
          return (
            (auth && isNull(QuoteService.quote)) ||
            (auth && QuoteService.isResiderQuote) ||
            (!auth && QuoteService.isGuestQuote)
          );
        }),
        switchMap(() => this.fetchData()),
      )
      .subscribe({
        next: result => {
          this.$behaviorCart.next(result);
          TrackingService.setTrackingData('cart', result);
        },
        error: err => LoggerService.logError('Fetch cart data failed!', err),
      });
  }

  _clearCartRoutine() {
    /**
     * clear cart for
     * following events
     */
    merge(
      EventsService.$event(LOGOUT),
      EventsService.$event(RESET_CART),
      EventsService.$event(ORDER_SUCCESS),
      EventsService.$event(ORDER_FAILURE),
    ).subscribe({
      next: () => {
        this.$behaviorCart.next(null);
        TrackingService.setTrackingData('cart', null);
      },
      error: err => LoggerService.logError('Clear cart failed!', err),
    });
  }

  _addCartParams(sku, quote_id, qty = 1, optionId = null, optionValue = null) {
    let result = {
      cartItem: {
        sku: sku,
        qty: qty,
        quote_id: quote_id,
      },
    };
    if (!isNull(optionId)) {
      result.cartItem['product_type'] = 'configurable';
      result.cartItem['product_option'] = {
        extension_attributes: {
          configurable_item_options: [
            {
              option_id: toString(optionId),
              option_value: optionValue,
            },
          ],
        },
      };
    }

    return result;
  }

  _requestDetail({quote}) {
    if (QuoteService.isResiderQuote)
      return RequestService.$authGet(
        `${config.base_path}${LocaleService.current}/rest/V3/carts/mine`,
      );
    else
      return RequestService.$get(
        `${config.base_path}${LocaleService.current}/rest/V3/guest-carts/${quote}`,
      );
  }

  _requestUpdate({id, sku, qty, quote}) {
    let tosend = {cartItem: {sku, qty, quote_id: quote}};
    if (QuoteService.isResiderQuote)
      return RequestService.$authPut(
        `${config.base_path}${LocaleService.current}/rest/V3/carts/mine/items/${id}`,
        {body: tosend},
      );
    else
      return RequestService.$put(
        `${config.base_path}${LocaleService.current}/rest/V3/guest-carts/${quote}/items/${id}`,
        {body: tosend},
      );
  }

  _requestSwitch({id, sku, qty, quote, type}) {
    let tosend = {cartItem: {sku, qty, quote_id: quote}};
    if (QuoteService.isResiderQuote)
      return RequestService.$authPut(
        `${config.base_path}/en/rest/V3/carts/mine/items/${id}/${type}`,
        {body: tosend},
      );
    else
      return RequestService.$put(
        `${config.base_path}rest/V3/guest-carts/${quote}/items/${id}/${type}`,
        {body: tosend},
      );
  }

  _requestSwitchAll({quote, type}) {
    // let tosend = {cartItem: {sku, qty, quote_id: quote}};
    if (QuoteService.isResiderQuote)
      return RequestService.$authPut(
        `${config.base_path}/en/rest/V3/carts/mine/${type}`,
        // {body: tosend},
      );
    else
      return RequestService.$put(
        `${config.base_path}rest/V3/guest-carts/${quote}/${type}`,
        // {body: tosend},
      );
  }

  _requestRemove({id, quote, type}) {
    if (QuoteService.isResiderQuote)
      return RequestService.$authDelete(
        `${config.base_path}${LocaleService.current}/rest/V3/carts/mine/items/${id}`,
      );
    else
      return RequestService.$delete(
        `${config.base_path}${LocaleService.current}/rest/V3/guest-carts/${quote}/items/${id}`,
      );
  }

  _requestAdd({sku, quote, option, recommendations}) {
    let tosend = this._addCartParams(sku, quote, 1, '188', option);
    if (QuoteService.isResiderQuote)
      return RequestService.$authPost(
        `${config.base_path}${LocaleService.current}/rest/V3/carts/mine/items${
          recommendations ? '/cart' : ''
        }`,
        {body: tosend},
      );
    else
      return RequestService.$post(
        `${config.base_path}${
          LocaleService.current
        }/rest/V3/guest-carts/${quote}${recommendations ? '/items' : ''}`,
        {body: tosend},
      );
  }
}
const CartService = new Cart();
export default CartService;
