import clsx from 'clsx';
import React from 'react';
import BottomTabs from '@root/common/BottomTabs';

import styles from './styles.module.scss';

function Layout({hidetabs, header, children}) {
  return (
    <div className={clsx(styles.layout, !hidetabs && styles.hasfooter, header && styles.hasheader)}>
      {header && <div className={styles.layout_head}>{header}</div>}
      <div className={clsx(styles.layout_content)}>{children}</div>
      {!hidetabs && <div className={styles.layout_tabs}><BottomTabs /></div>}
    </div>
  );
}

export default Layout;
