import clsx from 'clsx';
import React from 'react';
import propTypes from 'prop-types';
import LocaleService from "@root/services/locale";

function IconBox({name, className}) {
  return (
    <i className={clsx(`mdi mdi-${name}`, className)} style={{lineHeight: 0}}></i>
  )
}

IconBox.propTypes = {
  name: propTypes.string,
  className: propTypes.string
}

export default IconBox